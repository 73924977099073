<template>
  <base-section id="sub-processors">
    <base-section-heading title="Sub processors">
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col style="padding: 16px;">
<p>Tentifly OÜ may engage and use additional data processors with access to certain Customer Data (each, a “Sub-processor“) to deliver our Services. This page provides an overview of the identity, location, and role of each Sub-processor. Terms used and not defined on this page have the meaning set forth in the Terms of Service, Privacy Policy or superseding written agreement between Customer and Tentifly OÜ (the “Agreement“).</p>
<h1>Third Parties</h1>
<p>Tentifly OÜ currently uses Sub-processors to provide infrastructure services, customer support and notifications. Prior to engaging any Sub-processor, Tentifly OÜ evaluates their privacy, security and confidentiality practices. Furthermore, Tentifly OÜ executes an agreement implementing its applicable obligations.</p>
<h1>Sub-processors</h1>
<p>Tentifly OÜ may use the following Sub-processors to deliver our Services:
<v-row style="font-weight: bold;">
  <v-col cols="4">
Entity
</v-col><v-col cols="4">
Activities
</v-col><v-col cols="4">
Country
</v-col>
</v-row>
<v-row>
  <v-col cols="4">
1&1 Internet Limited
</v-col><v-col cols="4">
Cloud hosting and Email Service Provider
</v-col><v-col cols="4">
United Kingdom
</v-col>
</v-row>
</p>
<h1>Updates</h1>
<p>As our business evolves, the Sub-processors we engage in may also change. Upon such changes we provide the owner of the Customer’s account with notice of any Sub-processors changes to the extent required under the Agreement, along with posting such updates here.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "Sub-processors",

  data: () => ({
    
  })
};
</script>
